import React from "react";
import { Row } from "react-bootstrap";
import FormatQuoteTwoToneIcon from '@mui/icons-material/FormatQuoteTwoTone';
import ezsenseLogoImage from "../assets/logo.png";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <Row className="contact-us-wrapper">
        <div className="contact-us-top">
          <div className="contact-us-title">About Us
          </div>
          <p className="contact-us-explain">
            Elevate Your Wellness with Our Nutritional Expertise.
          </p>
        </div>
        <div className="contact-us-info">
          <div className="contact-us-info-item">
            <div className="upper">
              <FormatQuoteTwoToneIcon className="open-quote" />
            </div>
          </div>
          <div className="contact-us-info">
            <div className="contact-us-info-item">
              <p className="explain">
                Welcome to Ezsense Health, your premier destination for innovative, 
                high-quality health solutions. We specialize in curating a comprehensive range of 
                excellent food and health supplements to empower you to achieve optimal well-being.
              </p>
            </div>
          </div>
          <div className="contact-us-info">
            <div className="contact-us-info-item">
              <p className="explain">
                With our unwavering commitment to excellence and customer satisfaction, 
                we strive to redefine industry standards and deliver a seamless shopping 
                experience. Join us on this transformative journey toward a healthier lifestyle.
              </p>
            </div>
          </div>
        </div>
        <div className="contact-us-social">
        <img src={ezsenseLogoImage} className="ezsense-logo" alt='ezsense-logo'></img>

        </div>
      </Row>
    </div>
  );
};

export default ContactUs;
