import React from "react";
import { Row } from "react-bootstrap";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import SmartphoneIcon from "@mui/icons-material/Smartphone";
import shopeeLogoImage from "../assets/shopee-logo-contact.jpg";
import lazadaLogoImage from "../assets/lazada-logo-contact.jpg";

const ContactUs = () => {
  return (
    <div className="contact-us">
      <Row className="contact-us-wrapper">
        <div className="contact-us-top">
          <div className="contact-us-title">Get In Touch

          </div>
          <p className="contact-us-explain">
            We welcome your questions, feedback, and inquiries. Don't hesitate to
            reach out to our team – we're here to assist you.
          </p>
        </div>
        {/* <Lottie animationData={contactUs} className="lottie-div" /> */}

        <div className="contact-us-info">
          <div className="contact-us-info-item">
            <div className="upper">

              <div className="icon-wrapper"><MailOutlineIcon className="company-info-icon" />
              </div><div className="text"><p>EMAIL</p></div>
            </div>
            <div className="lower">
              <div className="words">Our friendly team is here to help.</div>

              <a className='company-info-text email' href="mailto:ezsensemalaysia@gmail.com">ezsensemalaysia@gmail.com</a>
            </div>

          </div>
          <div className="contact-us-info-item">
            <div className="upper">

              <div className="icon-wrapper"><LocationOnIcon className="company-info-icon" />
              </div><div className="text"><p>OFFICE</p></div>
            </div>
            <div className="lower">
              <div className="words">Come say hello at our office HQ.</div>

              <p className='company-info-text'>
                Ezsense Holdings Sdn. Bhd.<br />202001027178 (1383498-X)
              </p>
              <p className='company-info-text '>
                No 15, Lorong Perkasa Jaya, Taman Perkasa Jaya, 13000 Butterworth,
                Pulau Pinang, Malaysia.
              </p>
            </div>
          </div>

          <div className="contact-us-info-item">
            <div className="upper">

              <div className="icon-wrapper"><SmartphoneIcon className="company-info-icon" />
              </div><div className="text"><p>PHONE</p></div>
            </div>
            <div className="lower">
              <div className="words">Mon-Fri from 8am to 5pm</div>
              <a className='company-info-text email' href="tel:60126650369">+6012-6650369</a>
            </div>
          </div>
        </div>

        <div className="contact-us-social">
          <a
            href="https://www.facebook.com/ezsensemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <FacebookIcon className="social-icon" />
          </a>
          <a
            href="https://instagram.com/ezsensemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <InstagramIcon
              className="social-icon"
              style={{ margin: "0px 1px" }}
            />
          </a>
          <a
            href="https://wa.link/0pef8q"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <WhatsAppIcon className="social-icon" />
          </a>
          <a
            href="https://shopee.com.my/ezsensemalaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <img src={shopeeLogoImage} className="social-shopee-img" alt='shopee'></img>
          </a>
          <a
            href="https://www.lazada.com.my/shop/ezsense-malaysia"
            target="_blank"
            rel="noreferrer"
            className="social-link"
          >
            {" "}
            <img src={lazadaLogoImage} className="social-lazada-img" alt='lazada'></img>
          </a>
        </div>
      </Row>
    </div>
  );
};

export default ContactUs;
