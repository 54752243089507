const fiberzymeDescriptionEN = [
  "『 🇨🇭 Switzerland Formulation 🍇 Ezsense Fiberzyme perfectly presents people with comprehensive care that can detoxify, lose weight and resist aging. Suitable for 👪 all ages and 🥬 vegetarians. 』 ", " ",
  "Ezsense Fiberzyme - 8 Major Ingredients that Human Body Needs ", " ",
  "🍇 Mixed Fruits ",
  "🍐 Psyllium Husk ",
  "🍅 Inulin ",
  "🥬 Dandelion Extract ",
  "🍑 Milk Thistle Extract ",
  "🫐 Digestive Enzyme ",
  "🍏 Apple Stem Cell ",
  "🍊 Prebiotics ", , " ",
  "『 30 Major Benefits of Ezsense Fiberzyme』 ", " ",
  "🔎 Helps detoxify the whole body, eliminate edema, enhance satiety, promote gastrointestinal digestion, and relieve constipation. ", " ",
  "🔎 Helps break down carbohydrates, protein, fat, cellulose, and lactose. ", " ",
  "🔎 Helps detoxify the liver, reduce inflammation of liver cells, prevent toxins from invading the liver, stabilize the liver cell membrane, and repair liver damage. ", " ",
  "🔎 Reduce bad cholesterol (LDL), increase good cholesterol (HDL), reduce blood pressure, clear kidney toxins, and promote diuresis. ", " ",
  "🔎 Enhance antioxidant capacity, enhance the immune system, enhance metabolism, improve absorption of vitamins and minerals, and replenish prebiotics. ", " ",
  "🔎 Slow down aging speed, reduce wrinkles, reduce fine lines, promote the growth of new skin cells, and whiten skin. ", " ",
  "🌟 Recommended to take before bedtime  ",
  "🌟 Mix with 200 ML of room / warm water ",
  "🌟 Definitely will go to the toilet in 6-12 hours ",
  "🌟 Doesn’t cause a stomachache  ",
  "🌟 Recommended 4 Boxes for one treatment (2 months) ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100% Natural Ingredients ",
  "🥤 1 Box x 15 Sachets ", " ",

  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM69",
  "✔️ 2 boxes RM129",
  "✔️ 4 boxes RM239",
  "✔️ 6 boxes RM349",
  "✔️ 8 boxes RM459", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const fiberzymeDescriptionCN = [
  "『 🇨🇭 瑞士配方的 🍇 Ezsense Fiberzyme 完美呈现了既可以排毒又可以瘦身和抗老的全方位呵护！最重要是 👪 男女老少和 🥬 素食者都可以服用。』 ", " ",
  "【Ezsense Fiberzyme 集合 8 大种对人体非常重要的成分】 ", " ",
  "🍇 Mixed Fruits (混合水果) ",
  "🍐 Psyllium Husk (车前壳) ",
  "🍅 Inulin (菊淀粉) ",
  "🥬 Dandelion Extract  (蒲公英提取物) ",
  "🍑 Milk Thistle Extract (奶蓟草提取物) ",
  "🫐 Digestive Enzyme (消化酵素) ",
  "🍏 Apple Stem Cell (苹果干细胞) ",
  "🍊 Prebiotics (益生元） ", " ",
  "『Ezsense Fiberzyme 30 大功效』 ", " ",
  "🔎 助于全身排毒、消除水肿、增强饱腹感、促进肠胃消化、缓解便秘问题。 ", " ",
  "🔎 助于分解碳水化物、分解蛋白质、分解脂肪、分解纤维素、分解乳糖。 ", " ",
  "🔎 助于肝脏排毒、减少肝细胞炎症、防止毒素入侵肝脏、稳定肝细胞膜、修复肝损伤。 ", " ",
  "🔎 降低坏胆固醇、增加好胆固醇、降低血压、清理肾脏毒素、发挥利尿功效。 ", " ",
  "🔎 增强抗氧化能力、增强免疫系统、增强新陈代谢、改善维生素和矿物质的吸收、补充益生元。 ", " ",
  "🔎 减缓衰老速度、减少皱纹、减少细纹、促进皮肤新细胞生长、美白肌肤。 ", " ",
  "🌟 建议晚上睡前服用 ",
  "🌟 200 ML 开水/温水冲泡 ",
  "🌟 6-12 小时肯定会上厕所 ",
  "🌟 不会导致肚子绞痛 ",
  "🌟 建议 4 盒一个疗程 (2个月的分量) ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100% 纯天然成分 ",
  "🥤 1 盒 x 15 包 ", " ",

  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM69",
  "✔️ 2 盒 RM129",
  "✔️ 4 盒 RM239",
  "✔️ 6 盒 RM349",
  "✔️ 8 盒 RM459", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const cfsblockDescriptionEN = [
  "『 🇮🇹 Italy Formulation Ezsense CFSblock provides optimal well-being by effectively blocking carbohydrates, fats, and sugars. It offers a range of benefits, including promoting satiety, suppressing appetite, and reducing cravings. Additionally, it boosts energy levels and metabolism, which aids in calorie burning and facilitates weight loss. 』 ", " ",
  "Ezsense CFSblock - 9 Major Ingredients that Human Body Needs ", " ",
  "🥔 Cocoa Powder ",
  "🍊 Moro Sicilian Red Orange Fruit Extract ",
  "🥜 White Kidney Bean Powder ",
  "🫘 L-Theanine ",
  "🍠 Guarana Extract ",
  "🍈 Gymnema Extract ",
  "🍌 Phyllanthus Emblica Extract  ",
  "🫑 Shilajit ",
  "🐠 Omega-3 Powder (Fish Oil) ", " ",
  "『 30 Major Benefits of Ezsense CFSblock』 ", " ",
  "🔎 Helps block carbohydrate digestion and absorption, promotes satiety, promotes appetite control, reduces cravings, and enhances metabolic function. ", " ",
  "🔎 Helps reduces the desire for high-calorie foods, promotes calorie burning, and fat breakdown, enhances fat burning, and reduces body fat. ", " ",
  "🔎 Helps suppresses sugar cravings, reduces sugar digestion and absorption, regulates blood sugar levels, promotes nutrient absorption, and promotes weight loss. ", " ",
  "🔎 Helps aid in calorie utilization, provides an energy boost, improves insulin control, supports healthy digestion, and improves bowel movement. ", " ",
  "🔎 Helps promotes overall gut health, reduces stress, prevents emotional eating, supports fat oxidation, and reduces inflammation linked to obesity. ", " ",
  "🔎 Helps provides sustained energy and stamina, enhances exercise performance and endurance, promotes overall well-being, improves weight management, and improves sleep quality. ", " ",
  "🌟 Suitable for all ages ",
  "🌟 Recommended to drink before a meal ",
  "🌟 Mix with 100-150 ML of room / warm water ",
  "🌟 Recommended 4 Boxes for one treatment ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100% Natural Ingredients ",
  "📦 1 Box x 15 Sachets", " ",

  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM70",
  "✔️ 2 boxes RM130",
  "✔️ 4 boxes RM240",
  "✔️ 6 boxes RM350",
  "✔️ 8 boxes RM460", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const cfsblockDescriptionCN = [
  "『 🇮🇹 意大利配方 Ezsense CFSblock 通过有效阻断碳水化合物、脂肪和糖分来实现最佳健康。它具有一系列好处，包括促进饱腹感、抑制食欲和减少食欲。此外，它还能提高能量水平和新陈代谢，有助于卡路里燃烧并促进减肥。』 ", " ",
  "Ezsense CFSblock - 人体所需的 9 种主要成分 ", " ",
  "🥔 Cocoa Powder (可可粉) ",
  "🍊 Red Orange Extract (红橙果提取物) ",
  "🥜 White Kidney Bean (白芸豆) ",
  "🫘 L-Theanine (L-茶氨酸) ",
  "🍠 Guarana Extract (瓜拉那提取物) ",
  "🍈 Gymnema Extract (匙羹藤提取物) ",
  "🍌 Phyllanthus Emblica Extract (余甘子提取物) ",
  "🫑 Shilajit (喜来芝) ",
  "🐠 Omega-3 (Fish Oil) Omega-3（鱼油） ", " ",
  "『 Ezsense CFSblock 30 大优势』 ", " ",
  "🔎 帮助阻止碳水化合物的消化和吸收、促进饱腹感、促进食欲控制、减少食欲、增强新陈代谢功能。 ", " ",
  "🔎 帮助减少对高热量食物的渴望、促进热量燃烧、促进脂肪分解、增强脂肪燃烧、减少体内脂肪。 ", " ",
  "🔎 帮助抑制对糖的渴望、减少糖的消化和吸收、调节血糖水平、促进营养吸收、促进减肥。 ", " ",
  "🔎 帮助热量利用、提供能量、改善胰岛素控制、支持健康消化、改善排便。 ", " ",
  "🔎 帮助促进整体肠道健康、减轻压力、防止情绪化饮食、支持脂肪氧化、并减少与肥胖相关的炎症。 ", " ",
  "🔎 帮助提供持续的能量和耐力、增强运动表现和耐力、促进整体健康、改善体重管理、并提高睡眠质量。 ", " ",
  "🌟 适合所有年龄段 ",
  "🌟 建议餐前服用 ",
  "🌟 100-150 ML 室温水/温水冲泡 ",
  "🌟 建议 4 盒一个疗程 ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100%天然成分 ",
  "📦 1 盒 x 15 包 ", " ",

  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM70",
  "✔️ 2 盒 RM130",
  "✔️ 4 盒 RM240",
  "✔️ 6 盒 RM350",
  "✔️ 8 盒 RM460", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const opticmindDescriptionEN = [
  "『 🇺🇸 United States formulation Ezsense OpticMind enhances eye health, including vision and retina, while improving brain function and cognitive and memory performance. 』 ", " ",
  "Ezsense OpticMind – 10 Major Ingredients that Human Body Needs ", " ",
  "🍊 Orange Powder  ",
  "🍎 Tropical Fruit Powder ",
  "🍑 Ascorbic Acid (Vitamin C) ",
  "🥕 Bacopa Monnieri Extract ",
  "🥒 Ginkgo Biloba Extract ",
  "🐟 Omega-3 Powder (Fish Oil) ",
  "🫐 Bilberry Extract ",
  "🫑 Lutein ",
  "🥝 Zeaxanthin ",
  "🍍 Vitamin E ", " ",
  "『 30 Major Benefits of Ezsense OpticMind』 ", " ",
  "🔎 Helps protect the eye’s lens from oxidative damage, lowers the risk of cataracts, improves visual acuity, improves night vision, and prevents vision loss for older adults. ", " ",
  "🔎 Helps support overall eye health, enhances focus and object perception, maintains retinal health, reduces the risk of age-related macular degeneration, and protects the retina from blue light damage. ", " ",
  "🔎 Helps increase blood flow to the eyes, promotes ocular circulation, improves tear production to dry eye syndrome, alleviates eye fatigue, and reduces eye inflammation. ", " ",
  "🔎 Helps maintain optimal brain functions, alleviates symptoms related to depression and anxiety, improves cognitive abilities and learning abilities, and protects neurons from oxidative stress. ", " ",
  "🔎 Helps reduce the risk of neurodegenerative disorders, improve memory, prevent age-related cognitive decline, promote overall brain health, and support stress management. ", " ",
  "🔎 Helps reduce brain inflammation, enhance blood flow and circulation, support mental performance, and provide neuroprotection and optimal brain functions. ", " ",
  "🌟 Suitable for all ages ",
  "🌟 Recommended to take after meal ",
  "🌟 Mix with 100-150 ML of room / warm water ",
  "🌟 Recommended 4 Boxes for one treatment ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100% Natural Ingredients ",
  "📦 1 Box x 15 Sachets", " ",

  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM66",
  "✔️ 2 boxes RM126",
  "✔️ 4 boxes RM236",
  "✔️ 6 boxes RM346",
  "✔️ 8 boxes RM456", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const opticmindDescriptionCN = [
  "『 🇺🇸 美国配方 Ezsense OpticMind 增强眼睛健康，包括视力和视网膜，同时改善大脑功能以及认知和记忆表现。』 ", " ",
  "Ezsense OpticMind – 人体所需的 10 种主要成分 ", " ",
  "🍊 Orange Powder (橙粉) ",
  "🍎 Tropical Fruit Powder (热带水果粉) ",
  "🍑 Ascorbic Acid (Vitamin C (维生素C)) ",
  "🥕 Bacopa Monnieri Extract (假马齿苋提取物) ",
  "🥒 Ginkgo Biloba Extract (银杏叶提取物) ",
  "🐟 Omega-3  (Fish Oil) Omega-3 (鱼油) ",
  "🫐 Bilberry Extract (越橘提取物) ",
  "🫑 Lutein (叶黄素) ",
  "🥝 Zeaxanthin (玉米黄质) ",
  "🍍 Vitamin E (维生素E) ", " ",
  "『 Ezsense OpticMind 的 30 大优势』 ", " ",
  "🔎 有助于保护眼睛的晶状体免受氧化损伤、降低白内障的风险、提高视力、改善夜视能力、并防止老年人视力丧失。 ", " ",
  "🔎 帮助支持整体眼睛健康、增强焦点和物体感知、维持视网膜健康、降低年龄相关性黄斑变性的风险、并保护视网膜免受蓝光损伤。 ", " ",
  "🔎 帮助增加眼睛的血液流动、促进眼部循环、改善干眼症的泪水产生、缓解眼睛疲劳、减少眼部炎症。 ", " ",
  "🔎 帮助维持最佳的大脑功能、缓解与抑郁和焦虑相关的症状、提高认知能力和学习能力、并保护神经元免受氧化应激。 ", " ",
  "🔎 帮助降低神经退行性疾病的风险、改善记忆力、预防与年龄相关的认知能力下降、促进整体大脑健康、并支持压力管理。 ", " ",
  "🔎 帮助减少大脑炎症、增强血液流动和循环、支持精神表现、并提供神经保护和最佳大脑功能。 ", " ",
  "🌟 适合所有年龄段 ",
  "🌟 建议饭后服用 ",
  "🌟 100-150 ML 室温水/温水冲泡 ",
  "🌟 建议 4 盒一个疗程 ", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100%天然成分 ",
  "📦 1 盒 x 15 包", " ",

  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM66",
  "✔️ 2 盒 RM126",
  "✔️ 4 盒 RM236",
  "✔️ 6 盒 RM346",
  "✔️ 8 盒 RM456", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];


const feminacurveDescriptionEN = [
  "『 🇩🇪 Germany formulation Ezsense FeminaCurve achieves a series of benefits for women's health by effectively regulating women's endocrine hormones and estrogen, such as normal menstrual cycle, normal menstrual flow, relieving menstrual pain, reducing leucorrhoea, reducing odor, soothing vagina inflammation relief, UTI relief, enhances uterine health, enhances chances of conception, supports vaginal tightening, supports vaginal elasticity and tone, supports breast firmness, supports breast enlargement. 』"," ",
  "Ezsense FeminaCurve - 9 Major Ingredients that Human Body Needs"," ",
  "🍒 Raspberry",
  "🫐 Blueberry",
  "🫒 Blackberry",
  "🍈 Kacip Fatimah Extract",
  "🥬 Manjakani Extract",
  "🌱 Angelica Sinensis Extract",
  "🍀 Pueraria Mirifica Extract",
  "🥕 Pueraria Lobata Extract",
  "🍓 Cranberry"," ",
  "『 30 Major Benefits of Ezsense FeminaCurve』"," ",
  "🔎 Regulate menstrual secretion and cycle, regulate and balance hormones, and estrogen, control premenstrual symptoms, relieve menstrual discomfort, and regulate mood and mental health."," ",
  "🔎 Supports healthy vaginal flora, reduces the risk of vaginal infection and excessive vaginal discharge, supports urinary and bladder health, promotes vaginal tightening, and improves tissue tone and elasticity."," ",
  "🔎 Supports female uterine and reproductive health, supports fertility and conception, promotes postpartum wound healing and recovery, relieves vaginal dryness, enhances intimate desire and function, and reduces menopause symptoms."," ",
  "🔎 Support urinary tract health and prevent infection, support breast health and enhance breast firmness and size, improve menstrual anemia, boost immunity, increase vitality and energy, and regulate blood sugar levels."," ",
  "🔎 Supports cardiovascular health, supports bone health, reduces the risk of osteoporosis, promotes oral and dental health, promotes gut health, and supports skin health.", " ",
  "🌟 Suitable for females over 10 years old",
  "🌟 Recommended to drink before bedtime",
  "🌟 Mix with 100-150 ML of room / warm water",
  "🌟 Recommended 4 Boxes for one treatment", " ",
  "🏅SIRIM🏅HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD", " ",
  "🍀 100% Natural Ingredients",
  "📦 1 Box x 15 Sachets", " ",

  "【Direct manufacturing price】", " ",
  "✔️ 1 box RM88",
  "✔️ 2 boxes RM168",
  "✔️ 4 boxes RM318",
  "✔️ 6 boxes RM468",
  "✔️ 8 boxes RM608", " ",
  "🆓 Free shipping within Malaysia 🚛",
  "✉️ Contact us to place order now 📦",
];


const feminacurveDescriptionCN = [
  "『 🇩🇪 德国配方 Ezsense FeminaCurve 通过有效的调理女性的内分泌、荷尔蒙和雌激素从而实现女性健康的一系列好处，例如：月经周期正常、月经量正常、舒缓经痛、减少白带、减少异味、舒缓阴道炎、舒缓尿道炎、增强子宫健康、增强受孕机率、支持阴部收紧、支持阴道弹性和张力、支持胸部紧实、支持胸部增大。』 ", " ",
  "Ezsense FeminaCurve - 人体所需的 9 种主要成分 ", " ",
  "🍒 Raspberry (覆盆子) ",
  "🫐 Blueberry (蓝莓) ",
  "🫒 Blackberry (黑莓) ",
  "🍈 Kacip Fatimah Extract (卡琪花蒂玛提取物) ",
  "🥬 Manjakani Extract (曼加卡尼提取物) ",
  "🌱 Angelica Sinensis Extract (当归提取物) ",
  "🍀 Pueraria Mirifica Extract (野葛根提取物) ",
  "🥕 Pueraria Lobata Extract (葛根提取物) ",
  "🍓 Cranberry (蔓越莓提取物) ", " ",
  "『 Ezsense FeminaCurve 30 大功效』 ", " ",
  "🔎 调节月经分泌和周期、调节平衡荷尔蒙，调节平衡雌激素，控制经前症状 (PMS)、缓解经期不适、调节情绪和心理健康。 ", " ",
  "🔎 支持健康的阴道菌群、降低阴道感染的风险、减少过多的阴道分泌物、支持泌尿和膀胱健康、促进阴道收紧、改善阴道组织的张力和弹性。 ", " ",
  "🔎 支持女性子宫和生殖健康、支持生育和促进受孕、支持产后护理并促进愈合和恢复、缓解阴道干燥、增强女性的性欲和性功能、减轻更年期相关症状。 ", " ",
  "🔎 支持尿路健康和预防尿路感染 (UTI)、支持乳房健康和增强乳房的紧实和尺寸、改善月经贫血问题、增强免疫系统、增强活力和能量、调节血糖水平。 ", " ",
  "🔎 支持心血管健康、支持骨骼健康、降低骨质疏松症的风险、促进口腔健康、促进肠道健康、支持皮肤健康。 ", " ",
  "🌟 适合 10 岁以上发育女性 ",
  "🌟 建议晚上睡前服用 ",
  "🌟 100-150 ML 室温水/温水冲泡 ",
  "🌟 建议 4 盒一个疗程", " ",
  "🏅️SIRIM🏅️HACCP🏅GMP🏅KKM🏅HALAL🏅FOOD SAFETY SYSTEM🏅MESTI🏅EUROPEAN QUALITY AWARD ", " ",
  "🍀 100%天然成分 ",
  "📦 1 盒 x 15 包", " ",

  "【厂家公司销售价，绝对让你兴奋】", " ",
  "✔️ 1 盒 RM88",
  "✔️ 2 盒 RM168",
  "✔️ 4 盒 RM318",
  "✔️ 6 盒 RM468",
  "✔️ 8 盒 RM608", " ",
  "🆓 全马包邮 🚛",
  "✉️ 赶紧联系我们下单吧 📦",
];

export const data = {
  fiberzyme: { CN: fiberzymeDescriptionCN, EN: fiberzymeDescriptionEN },
  cfsblock: { CN: cfsblockDescriptionCN, EN: cfsblockDescriptionEN },
  opticmind: { CN: opticmindDescriptionCN, EN: opticmindDescriptionEN },
  feminacurve: { CN: feminacurveDescriptionCN, EN: feminacurveDescriptionEN },
};
