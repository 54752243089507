import fiberzymeCover from '../assets/fiberzyme/cover.PNG'
import fiberzymeIngredientEN1 from '../assets/fiberzyme/ingredient-en1.JPG'
import fiberzymeIngredientEN2 from '../assets/fiberzyme/ingredient-en2.JPG'
import fiberzymeIngredientCN1 from '../assets/fiberzyme/ingredient-cn1.JPG'
import fiberzymeIngredientCN2 from '../assets/fiberzyme/ingredient-cn2.JPG'
import fiberzyme1 from '../assets/fiberzyme/1.JPG'
import fiberzyme2 from '../assets/fiberzyme/2.JPG'
import fiberzyme3 from '../assets/fiberzyme/3.JPG'
import fiberzyme4 from '../assets/fiberzyme/4.JPG'
import fiberzyme5 from '../assets/fiberzyme/5.JPG'
import fiberzyme6 from '../assets/fiberzyme/6.JPG'
import fiberzyme7 from '../assets/fiberzyme/7.JPG'
import fiberzyme8 from '../assets/fiberzyme/8.JPG'
import fiberzyme9 from '../assets/fiberzyme/9.JPG'

import cfsblockCover from '../assets/cfsblock/cover.PNG'
import cfsblockIngredientCN1 from '../assets/cfsblock/ingredient-cn1.JPG'
import cfsblockIngredientCN2 from '../assets/cfsblock/ingredient-cn2.JPG'
import cfsblockIngredientEN1 from '../assets/cfsblock/ingredient-en1.JPG'
import cfsblockIngredientEN2 from '../assets/cfsblock/ingredient-en2.JPG'
import cfsblock1 from '../assets/cfsblock/1.JPG'
import cfsblock2 from '../assets/cfsblock/2.JPG'
import cfsblock3 from '../assets/cfsblock/3.JPG'
import cfsblock4 from '../assets/cfsblock/4.JPG'
import cfsblock5 from '../assets/cfsblock/5.JPG'
import cfsblock6 from '../assets/cfsblock/6.JPG'
import cfsblock7 from '../assets/cfsblock/7.JPG'
import cfsblock8 from '../assets/cfsblock/8.JPG'
import cfsblock9 from '../assets/cfsblock/9.JPG'

import opticmindCover from '../assets/opticmind/cover.PNG'
import opticmindIngredientCN1 from '../assets/opticmind/ingredient-cn1.JPG'
import opticmindIngredientCN2 from '../assets/opticmind/ingredient-cn2.JPG'
import opticmindIngredientEN1 from '../assets/opticmind/ingredient-en1.JPG'
import opticmindIngredientEN2 from '../assets/opticmind/ingredient-en2.JPG'
import opticmind1 from '../assets/opticmind/1.JPG'
import opticmind2 from '../assets/opticmind/2.JPG'
import opticmind3 from '../assets/opticmind/3.JPG'
import opticmind4 from '../assets/opticmind/4.JPG'
import opticmind5 from '../assets/opticmind/5.JPG'
import opticmind6 from '../assets/opticmind/6.JPG'
import opticmind7 from '../assets/opticmind/7.JPG'
import opticmind8 from '../assets/opticmind/8.JPG'
import opticmind9 from '../assets/opticmind/9.JPG'

import feminacurveCover from '../assets/feminacurve/cover.PNG'
import feminacurveIngredientCN1 from '../assets/feminacurve/ingredient-cn1.JPG'
import feminacurveIngredientCN2 from '../assets/feminacurve/ingredient-cn2.JPG'
import feminacurveIngredientEN1 from '../assets/feminacurve/ingredient-en1.JPG'
import feminacurveIngredientEN2 from '../assets/feminacurve/ingredient-en2.JPG'
import feminacurve1 from '../assets/feminacurve/1.JPG'
import feminacurve2 from '../assets/feminacurve/2.JPG'
import feminacurve3 from '../assets/feminacurve/3.JPG'
import feminacurve4 from '../assets/feminacurve/4.JPG'
import feminacurve5 from '../assets/feminacurve/5.JPG'
import feminacurve6 from '../assets/feminacurve/6.JPG'
import feminacurve7 from '../assets/feminacurve/7.JPG'
import feminacurve8 from '../assets/feminacurve/8.JPG'
import feminacurve9 from '../assets/feminacurve/9.JPG'

export const fiberzymeImage = [fiberzymeCover, fiberzyme1, fiberzyme2, fiberzyme3, fiberzyme4, fiberzyme5, fiberzyme6, fiberzyme7, fiberzyme8, fiberzyme9, fiberzymeIngredientEN1, fiberzymeIngredientEN2, fiberzymeIngredientCN1, fiberzymeIngredientCN2]
export const cfsblockImage = [cfsblockCover, cfsblock1, cfsblock2, cfsblock3, cfsblock4, cfsblock5, cfsblock6, cfsblock7, cfsblock8, cfsblock9, cfsblockIngredientEN1, cfsblockIngredientEN2, cfsblockIngredientCN1, cfsblockIngredientCN2]
export const opticmindImage = [opticmindCover, opticmind1, opticmind2, opticmind3, opticmind4, opticmind5, opticmind6, opticmind7, opticmind8, opticmind9, opticmindIngredientEN1, opticmindIngredientEN2, opticmindIngredientCN1, opticmindIngredientCN2]
export const feminacurveImage = [feminacurveCover, feminacurve1, feminacurve2, feminacurve3, feminacurve4, feminacurve5, feminacurve6, feminacurve7, feminacurve8, feminacurve9, feminacurveIngredientEN1, feminacurveIngredientEN2, feminacurveIngredientCN1, feminacurveIngredientCN2]